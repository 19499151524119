export default {
  _parseGuide(url: string) {
    const regExpYoutube =
      /^.*(youtu.be\/|\/v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

    const regExpGDrive = /^.*(drive.google.com\/file\/d\/)([^#&?]*)\//;

    const regExpNewGDrive =
      /^.*(docs.google.com\/(presentation|document)\/d\/)([^#&?]*)\//;

    const regExpH5p = /^.*(h5p_embed).*/;

    if (!url) return {};

    if (url.match(regExpYoutube)) {
      const match = url.match(regExpYoutube);

      if (match && !url.match(/&t=/)) {
        return {
          type: "youtube",
          url: `https://www.youtube.com/embed/${match[2]}?enablejsapi=1`,
        };
      }

      const timestampStr = url.match(/(?:t=)(.*)/)?.[1];
      const timestampTimes = timestampStr?.match(/[0-9][0-9]/g)?.reverse();

      //turn timestamp into second for embedding
      let mult = 1;
      let timestamp = 0;

      timestampTimes?.forEach((val: any) => {
        timestamp += parseInt(val) * mult;
        mult *= 60;
      });

      return {
        type: "youtube",
        url: `https://www.youtube.com/embed/${match?.[2]}?start=${
          timestamp ? timestamp : "01"
        }&enablejsapi=1`,
      };
    }

    if (url.match(regExpGDrive) || url.match(regExpNewGDrive)) {
      let match = url.match(regExpGDrive);

      if (!match) {
        match = url.match(regExpNewGDrive);
      }

      return {
        type: "gdrive",
        url: `https://drive.google.com/file/d/${
          match?.[3] || match?.[2]
        }/preview`,
      };
    }

    if (url.match(/\.jpg|\.jpeg|\.png|\.gif/)) {
      return {
        type: "image",
        url: url.split("?")[0],
      };
    }

    if (regExpH5p.test(url)) {
      return {
        type: "h5p",
        url: url,
      };
    }

    if (url) {
      return {
        type: "unknown",
        url: url,
      };
    }

    return {
      url: null,
    };
  },
};
