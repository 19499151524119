var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('Loadable',{attrs:{"items":_vm.course}}),(_vm.course)?_c('b-row',{staticClass:"lesson-row"},[_c('b-col',{attrs:{"xl":"8","sm":"12"}},[_c('header',{staticClass:"d-flex flex-column align-items-start mb-4"},[_c('div',{staticClass:"d-flex align-items-center title mb-2"},[(_vm.course._path_courses)?_c('a',{staticClass:"z-icon-wrapper go-back mr-3",attrs:{"href":`/seria/${_vm.pathSlug}`}},[_c('svg-caret-icon')],1):_vm._e(),(_vm.course._path_courses)?_c('span',{staticClass:"tag w-background-secondary w-color-white mr-2"},[_c('svg-ribbon-icon',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t("series"))+" ")],1):_vm._e(),(_vm.showLanguages)?_c('Subtitles',{attrs:{"languages":_vm.subtitleLanguages,"popupEnabled":true}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap w-100"},[_c('h1',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.course.attributes.name)+" ")]),_c('ToggleStarred',{staticClass:"toggle-starred",attrs:{"starText":_vm.$t('star-course'),"unstarText":_vm.$t('unstar-course'),"model":"course","id":_vm.course.id,"starred":_vm.starred,"highlighted":"true"}})],1)]),_c('LessonHero',{attrs:{"lesson":_vm.course}}),(_vm.course.task_for_current_course)?_c('LessonNote',{attrs:{"taskNote":_vm.course.task_for_current_course}}):_vm._e()],1),_c('b-col',{staticClass:"courses-list-col",attrs:{"order":"1","order-xl":"0","xl":"4","sm":"12"}},[(
          _vm.course._path_courses ||
          (_vm.course._recommended && _vm.course._recommended.length)
        )?_c('CourseListCard',{staticClass:"mt-3 mt-md-5 mt-xl-0",attrs:{"cnp":_vm.course._path_courses || _vm.course._recommended,"title":_vm.course._path_courses ? 'Kapitoly' : 'Odporúčané kurzy',"showCompleted":!!_vm.course._path_courses,"coursePlayerMode":_vm.course._path_courses ? 'path' : 'course',"pathSlug":_vm.pathSlug}}):_vm._e()],1),_c('b-col',{staticClass:"lesson-content",attrs:{"xl":"8"}},[(_vm.tabs)?_c('LessonContentTabs',{staticClass:"mb-4",attrs:{"tabs":_vm.tabs,"lesson":_vm.course,"active-tab":_vm.activeTab},on:{"tab-changed":_vm.onTabChanged}}):_vm._e(),(_vm.activeTab === 'description')?_c('LessonContentDetail',{attrs:{"content":_vm.course.attributes.content || _vm.course.attributes.description,"summary":(_vm.userType != 'student' && _vm.course.attributes.teacherSummary) || null,"tags":(_vm.userType != 'student' && _vm.course.attributes.teacherTagsString) ||
          null,"subjects":(_vm.userType != 'student' && _vm.course._subjects) || null,"links":_vm.course._links,"resources":_vm.course._resources}}):_vm._e()],1),(
        _vm.course.attributes.partners.data &&
        _vm.course.attributes.partners.data.length &&
        _vm.activeTab === 'description'
      )?_c('b-col',{staticClass:"lesson-content mt-5 mt-xl-0",attrs:{"order":"2","order-xl":"0","xl":"8"}},[_c('section',[_c('LessonContentDetailPartners',{attrs:{"partners":_vm.course.attributes.partners.data}})],1)]):_vm._e(),(_vm.activeTab === 'quiz')?_c('b-col',{staticClass:"quiz-content mb-3 mb-sm-0",attrs:{"xl":"8"}},[_c('LessonContentQuiz',{attrs:{"course":_vm.course}})],1):_vm._e(),(_vm.activeTab === 'teacher_materials')?_c('b-col',{staticClass:"lesson-content mb-3 mb-sm-0",attrs:{"xl":"8"}},[_c('LessonContentMaterials',{attrs:{"resources":_vm.course._teacher_material}})],1):_vm._e(),(_vm.activeTab === 'worksheet')?_c('b-col',{staticClass:"lesson-content mb-3 mb-sm-0",attrs:{"xl":"8"}},[_c('LessonContentWorksheet',{attrs:{"text":_vm.course.attributes.worksheetText,"document":_vm.course._worksheet_document}})],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }