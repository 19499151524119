import { render, staticRenderFns } from "./[courseSlug].vue?vue&type=template&id=09e88eb8&scoped=true"
import script from "./[courseSlug].vue?vue&type=script&lang=js"
export * from "./[courseSlug].vue?vue&type=script&lang=js"
import style0 from "./[courseSlug].vue?vue&type=style&index=0&id=09e88eb8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09e88eb8",
  null
  
)

export default component.exports