<template>
  <b-container>
    <Loadable :items="course" />
    <b-row v-if="course" class="lesson-row">
      <b-col xl="8" sm="12">
        <header class="d-flex flex-column align-items-start mb-4">
          <div class="d-flex align-items-center title mb-2">
            <a
              v-if="course._path_courses"
              class="z-icon-wrapper go-back mr-3"
              :href="`/seria/${pathSlug}`"
            >
              <svg-caret-icon />
            </a>
            <span
              v-if="course._path_courses"
              class="tag w-background-secondary w-color-white mr-2"
            >
              <svg-ribbon-icon class="mr-2" />
              {{ $t("series") }}
            </span>
            <Subtitles
              v-if="showLanguages"
              :languages="subtitleLanguages"
              :popupEnabled="true"
            />
          </div>
          <div
            class="d-flex align-items-center justify-content-between flex-wrap w-100"
          >
            <h1 class="mb-0">
              {{ course.attributes.name }}
            </h1>
            <ToggleStarred
              class="toggle-starred"
              :starText="$t('star-course')"
              :unstarText="$t('unstar-course')"
              model="course"
              :id="course.id"
              :starred="starred"
              highlighted="true"
            />
          </div>
        </header>
        <LessonHero :lesson="course" />
        <LessonNote
          v-if="course.task_for_current_course"
          :taskNote="course.task_for_current_course"
        />
      </b-col>
      <b-col order="1" order-xl="0" xl="4" sm="12" class="courses-list-col">
        <CourseListCard
          v-if="
            course._path_courses ||
            (course._recommended && course._recommended.length)
          "
          class="mt-3 mt-md-5 mt-xl-0"
          :cnp="course._path_courses || course._recommended"
          :title="course._path_courses ? 'Kapitoly' : 'Odporúčané kurzy'"
          :showCompleted="!!course._path_courses"
          :coursePlayerMode="course._path_courses ? 'path' : 'course'"
          :pathSlug="pathSlug"
        />
      </b-col>

      <b-col xl="8" class="lesson-content">
        <LessonContentTabs
          v-if="tabs"
          class="mb-4"
          :tabs="tabs"
          :lesson="course"
          :active-tab="activeTab"
          @tab-changed="onTabChanged"
        />
        <LessonContentDetail
          v-if="activeTab === 'description'"
          :content="course.attributes.content || course.attributes.description"
          :summary="
            (userType != 'student' && course.attributes.teacherSummary) || null
          "
          :tags="
            (userType != 'student' && course.attributes.teacherTagsString) ||
            null
          "
          :subjects="(userType != 'student' && course._subjects) || null"
          :links="course._links"
          :resources="course._resources"
        />
      </b-col>
      <b-col
        order="2"
        order-xl="0"
        xl="8"
        class="lesson-content mt-5 mt-xl-0"
        v-if="
          course.attributes.partners.data &&
          course.attributes.partners.data.length &&
          activeTab === 'description'
        "
      >
        <section>
          <LessonContentDetailPartners
            :partners="course.attributes.partners.data"
          />
        </section>
      </b-col>
      <b-col
        xl="8"
        class="quiz-content mb-3 mb-sm-0"
        v-if="activeTab === 'quiz'"
      >
        <LessonContentQuiz :course="course" />
      </b-col>

      <b-col
        xl="8"
        class="lesson-content mb-3 mb-sm-0"
        v-if="activeTab === 'teacher_materials'"
      >
        <LessonContentMaterials :resources="course._teacher_material" />
      </b-col>

      <b-col
        xl="8"
        class="lesson-content mb-3 mb-sm-0"
        v-if="activeTab === 'worksheet'"
      >
        <LessonContentWorksheet
          :text="course.attributes.worksheetText"
          :document="course._worksheet_document"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "/utils/axios";
import guideUtils from "/utils/guide";
import {
  injectCourseMetaFields,
  normalizeEnumEntityResponse,
} from "/utils/helpers";
import { get } from "lodash";
import * as Sentry from "@sentry/vue";

const api = {
  course: async (slug) => {
    const response = await axios.getAuth("courses", {
      params: {
        filters: {
          slug,
        },
        populate: [
          "thumbnail",
          "subjects.info",
          "topics.info",
          "partners.logo",
          "recommendedCourses.thumbnail",
          "recommendedPaths.thumbnail",
          "subtitleLanguages.flag",
          "quiz.form",
        ],
      },
    });

    if (response.data) {
      return response.data[0] || null;
    }

    return null;
  },
  path: async (slug) => {
    const response = await axios.getAuth("paths", {
      params: {
        filters: {
          slug,
        },
        populate: [
          "thumbnail",
          "subjects.info",
          "topics.info",
          "courses.thumbnail",
          "partners.logo",
        ],
      },
    });

    if (response.data) {
      return response.data[0] || null;
    }

    return null;
  },
};

export default {
  components: {
    CourseListCard: () => import("/components/CourseListCard.vue"),
    LessonHero: () => import("/components/Lesson/LessonHero.vue"),
    "svg-caret-icon": () => import("/assets/icons/caret.svg?inline"),
    "svg-ribbon-icon": () => import("/assets/icons/ribbon.svg?inline"),
    LessonContentTabs: () =>
      import("/components/Lesson/Content/LessonContentTabs.vue"),
    LessonContentDetail: () =>
      import("/components/Lesson/Content/LessonContentDetail.vue"),
    LessonContentQuiz: () =>
      import("/components/Lesson/Content/LessonContentQuiz.vue"),
    LessonContentMaterials: () =>
      import("/components/Lesson/Content/LessonContentMaterials.vue"),
    LessonContentWorksheet: () =>
      import("/components/Lesson/Content/LessonContentWorksheet.vue"),
    LessonContentDetailPartners: () =>
      import("/components/Lesson/Content/LessonContentDetailPartners.vue"),
    LessonNote: () => import("/components/Lesson/Note/LessonNote.vue"),
    ToggleStarred: () => import("/components/ToggleStarred.vue"),
    Subtitles: () => import("/components/Subtitles/Subtitles.vue"),
    Loadable: () => import("/components/Loadable.vue"),
  },

  data() {
    return {
      pathSlug: null,
      course: null,
      tabs: null,
      activeTab: "description",
      subtitleLanguages: undefined,
      showLanguages: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    userType() {
      const user = this.$store.getters["auth/user"];
      return user && user.type;
    },
    starred() {
      return (
        this.isLoggedIn &&
        (this.user.favoriteCourses || []).some(
          ({ id }) => id === this.course.id
        )
      );
    },
  },

  watch: {
    $route() {
      this._loadData();
    },
    isLoggedIn() {
      this._loadData();
    },
  },

  async created() {
    await this._loadData();

    if (!this.isLoggedIn && !get(this.course, "attributes.public")) {
      this.$router.push("/prihlasit-sa");
    }

    this.$eventLogger.userLog("course.view", {
      course_slug: this.$route.params.courseSlug,
    });
  },

  methods: {
    onTabChanged(activeTab) {
      this.activeTab = activeTab;
    },

    async _loadData() {
      try {
        this.pathSlug = this.$route.query.seria;

        const [course, path] = await Promise.all([
          api.course(this.$route.params.courseSlug),
          this.pathSlug ? api.path(this.pathSlug) : Promise.resolve(null),
        ]);

        // temp fixes until BE implements full resources in API for lesson detail

        this.course = this._mapCourseData(course, path);
        this.subtitleLanguages = get(
          this.course,
          "attributes.subtitleLanguages.data"
        );
        this.showLanguages = this.subtitleLanguages
          ? this.subtitleLanguages.length !== 0
          : false;
        this._setupTabs(this.course);
      } catch (error) {
        if (error.response.status === 401) {
          this.$router.push("/prihlasit-sa");
          return;
        }
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },

    _setupTabs(course) {
      this.tabs = [
        { id: "description", title: "Opis kurzu", borderColor: "primary" },
      ];

      this.activeTab = "description";

      if (get(course, "attributes.quiz.data")) {
        this.tabs.push({
          id: "quiz",
          title: "Kvíz",
          borderColor: "orange",
          tooltip:
            "Ak chcete vidieť výsledky vašich žiakov, zadajte im tento kurz ako úlohu. Viac info v sekcii Pomocník v Profile.",
        });
      }

      if (
        get(course, "attributes.teacherMaterial") &&
        ((!this.isLoggedIn && course.is_public) || this.userType === "teacher")
      ) {
        this.tabs.push({
          id: "teacher_materials",
          title: "Metodické materiály",
          borderColor: "secondary",
        });
      }

      if (get(course, "attributes.worksheetSectionName")) {
        this.tabs.push({
          id: "worksheet",
          title: course.attributes.worksheetSectionName,
          borderColor: "secondary",
        });
      }
    },

    _mapCourseData(course, path) {
      course._guide = guideUtils._parseGuide(course.attributes.videoLink, true);

      // lesson.teacher_material is boolean for unauth visitors of public courses and a string for the rest
      try {
        course._teacher_material =
          course.attributes.teacherMaterial &&
          course.attributes.teacherMaterial.split("\n\n").map((material) => {
            const properties = material.split("\n");

            return {
              title: properties[0],
              link: properties[1],
              download_link: properties[2],
            };
          });
      } catch (error) {
        course._teacher_material = null;
      }

      course._worksheet_document = {
        link: course.attributes.worksheetDocumentLink,
        download_link: course.attributes.worksheetDocumentDownloadLink,
      };

      // Transform slugs of recommended courses/series into objects
      const recommendedCourses =
        get(course, "attributes.recommendedCourses.data") || [];

      const recommendedPaths =
        get(course, "attributes.recommendedPaths.data") || [];

      recommendedCourses.forEach((course) => {
        injectCourseMetaFields(course, "course");
      });

      recommendedPaths.forEach((path) => {
        injectCourseMetaFields(path, "path");
      });

      course._recommended = [...recommendedCourses, ...recommendedPaths];

      const pathCourses = get(path, "attributes.courses.data");

      if (pathCourses) {
        pathCourses.forEach((course) => {
          injectCourseMetaFields(course, "course");
          course.slug += `?seria=${path.attributes.slug}`;
        });
      }

      course._path_courses = pathCourses;

      course._subjects = normalizeEnumEntityResponse(
        get(course, "attributes.subjects") || get(path, "attributes.subjects")
      );

      course._links = get(course, "attributes.links");

      course._resources = get(course, "attributes.resources");

      return course;
    },
  },
};
</script>

<style lang="scss" scoped>
section,
::v-deep section {
  padding: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;

  .z-icon-wrapper {
    margin-right: 1.5rem;
  }
}

::v-deep h3 {
  border-bottom: 1px solid var(--a-color-blue-border);
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
}

.lesson-content ::v-deep iframe {
  width: 100%;
  height: 100vh;
  border: 1px solid var(--a-color-blue-light);
  border-radius: 0.675rem;
}

.quiz-content ::v-deep iframe {
  width: 100%;
  border: 1px solid var(--a-color-blue-light);
  border-radius: 0.675rem;
}

.go-back {
  border: 1px solid var(--a-color-blue-border);

  svg {
    transform: rotate(90deg) translateY(1px);
  }
}

.toggle-starred {
  white-space: nowrap;
}

h1 {
  line-height: 1.5rem;
  font-size: 1.5rem;
  margin: 0;
}

.tag {
  display: flex;
  height: 2rem;
  padding: 6px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .lesson-row {
    position: relative;
  }

  .courses-list-col {
    position: absolute;
    right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .lock-info-text {
    margin-right: auto;
  }
}
</style>
